// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { Provider as ReduxProvider } from "react-redux";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";
import BottomMenu from "./components/bottom-menu/BottomMenu";
import { AuthProvider, msalConfig } from "./auth/MTSContext";
import { store } from "./redux/store";

export const adClient = new PublicClientApplication(msalConfig);
// ----------------------------------------------------------------------

export default function App() {
  return (
    <MsalProvider instance={adClient}>
      <AuthProvider>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <SettingsProvider>
              <BrowserRouter>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <ThemeLocalization>
                        <SnackbarProvider>
                          <div style={{ paddingBottom: "56px" }}>
                            {" "}
                            {/* Adjust padding to avoid overlap with bottom menu */}
                            <Router />
                          </div>
                          <BottomMenu />
                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </SettingsProvider>
          </ReduxProvider>
        </HelmetProvider>
      </AuthProvider>
    </MsalProvider>
  );
}
