import { createSlice, Dispatch } from "@reduxjs/toolkit";
// utils
import SalesOrdersService from "../../services/SalesOrdersService";
import { IBreakdownState } from "../../@types/breakdown";

// ----------------------------------------------------------------------

const initialState: IBreakdownState = {
  isLoading: false,
  error: null,
  data: [],
  page: 0,
  per_page: 20,
  total: 0,
  total_pages: 0,
};

const slice = createSlice({
  name: "breakdown",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BREAKDOWN
    getBreakdownsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload.data.map((item: any) => ({
        header: {
          ...item.breakdownHeader,
          createdAt: new Date(item.breakdownHeader.createdAt),
          documentDate: new Date(item.breakdownHeader.documentDate),
        },
        details: item.breakdownDetails,
      }));
      state.page = action.payload.page;
      state.per_page = action.payload.perPage;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getBreakdownsSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getBreakdowns() {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const response = await SalesOrdersService.getPagedBreakdowns();
      dispatch(getBreakdownsSuccess({ data: response }));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
