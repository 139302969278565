import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { Configuration, PopupRequest } from "@azure/msal-browser";
// config
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios from "axios";
import { AD_API, HOST_API, HOST_API_KEY } from "../config-global";
//
import {
  ActionMapType,
  AuthStateType,
  AuthUserType, 
  MTSB2CContextType,
} from "./types";

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    idToken: string;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    idToken: string;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  idToken: "",
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      idToken: action.payload.idToken,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      idToken: action.payload.idToken,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      idToken: "",
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<MTSB2CContextType | null>(null);

// ----------------------------------------------------------------------

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: AD_API.clientId,
    authority: `https://login.microsoftonline.com/${AD_API.authority}`,
    redirectUri: AD_API.redirectUri,
    postLogoutRedirectUri: "/",
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const { instance, accounts } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      axios.interceptors.response.use(
        (response) => response,
        (error) =>
          Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
          )
      );

      axios.interceptors.request.use(async (config) => {
        config.baseURL = HOST_API;
        if (!accounts[0]) {
          throw Error("No active account! Verify a user has been signed in.");
        }

        const response = await instance.acquireTokenSilent({
          scopes: AD_API.scopes,
          account: accounts[0],
        });

        const bearer = `Bearer ${response.accessToken}`;
        config.headers.Authorization = bearer;
        config.headers.idToken = response.idToken;

        config.headers["Ocp-Apim-Subscription-Key"] = HOST_API_KEY;
        return config;
      });
    }
  }, [isAuthenticated, accounts, instance]);

  const initialize = useCallback(async () => {
    try {
      if (isAuthenticated) {
        const account = accounts[0];
        const result = await instance.acquireTokenSilent({
          scopes: loginRequest.scopes,
          account,
        });

        if (result) {
          const user = await callMsGraph(result.accessToken);

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated,
              idToken: result.idToken,
              user: {
                ...user,
                displayName: user?.name,
                photoURL: user?.picture,
                role: "admin",
              },
            },
          });
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            idToken: "",
            isAuthenticated,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          idToken: "",
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [accounts, instance, isAuthenticated]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const doLogin = useCallback(async () => {
    await instance.loginRedirect(loginRequest);
  }, [instance]);

  // LOGOUT
  const logout = useCallback(async () => {
    await instance.logout();
    dispatch({
      type: Types.LOGOUT,
    });
  }, [instance]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "mts",
      doLogin,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, doLogin, logout]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
