import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Autocomplete,
} from "@mui/material";

import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import StockService from "../../services/StockService";
import { IStock } from "../../@types/stock";
import { IBreakdown, IBreakdownLineDto } from "../../@types/breakdown";
import { useNavigate } from "react-router-dom";
import SalesOrdersService from "../../services/SalesOrdersService";

interface NewItemFormProps {
  breakdown?: IBreakdown;
}

export default function NewItemForm({ breakdown }: NewItemFormProps) {
  const [lineNo, setLineNo] = useState(0);
  const [no, setNo] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [treadDepth, setTreadDepth] = useState(0);
  const [position, setPosition] = useState("");
  const [oldPosition, setOldPosition] = useState("");
  const [serialNumberRetired, setSerialNumberRetired] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [removalReason, setRemovalReason] = useState("");
  const [stockList, setStockList] = useState<IStock[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStock = async () => {
      const company = breakdown?.company ?? "";
      //console.log('Fetching stock for company:', company);
      const stock = await StockService.getMyStock(company);
      //console.log('Stock:', stock);
      setStockList(stock);
    };
    const calculateLineNo = () => {
      let lineNo = 0;
      if (breakdown)
        if (breakdown.orderLines) lineNo = breakdown.orderLines.length;

      const maxLineNo = lineNo + 1;
      setLineNo(maxLineNo * 10000);
    };

    fetchStock();
    calculateLineNo();
  }, [breakdown]);

  const refreshStocks = async () => {
    const company = breakdown?.company ?? "";
    //console.log('Refreshing stock for company:', company);
    const data = await StockService.refreshStock(company);
    //console.log('Stock:', data);
    setStockList(data);
  };

  const handleSave = async () => {
    const stockItem: IBreakdownLineDto = {
      no: no,
      description: description,
      quantity: quantity,
      treadDepth: treadDepth,
      position: position,
      oldPosition: oldPosition,
      serialNoRetired: serialNumberRetired,
      serialNo: serialNumber,
      reasonForRemoval: removalReason,
    };
    if (breakdown?.id) {
      await SalesOrdersService.addLineToOrder(breakdown.id, stockItem);
      window.location.href = `/salesorders/${breakdown.id}`;
    } else {
      console.error("Breakdown ID is missing");
      // Handle the case where breakdown.id is undefined
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const borderColor = breakdown?.company === "TAB" ? "darkgreen" : "black";

  return (
    <Card
      sx={{
        mt: 1,
        mx: 2,
        borderRadius: "10px",
        border: "1px solid",
        borderColor: borderColor,
        cursor: "pointer",
        position: "relative",
        backgroundColor: "#fbfbfb",
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "10px", textAlign: "left" }}
          >
            {breakdown?.selltoCustomerName}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "9px",
              textAlign: "right",
              color: breakdown?.company === "TAB" ? "darkgreen" : "black",
            }}
          >
            {breakdown?.company}
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{ mb: 2, fontSize: "9px", textAlign: "right" }}
        >
          Line No: {lineNo}
        </Typography>
        <Grid container spacing={2}>
          {/* No. Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                No*
              </Typography>
              <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
                <IconButton
                  sx={{ mr: 1, backgroundColor: "red", color: "white" }}
                  onClick={refreshStocks}
                >
                  <RotateLeftIcon />
                </IconButton>
                <Autocomplete
                  options={stockList}
                  getOptionLabel={(option) => option.itemNo || "Unknown"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: "white",
                        borderColor: "black",
                        borderRadius: "0px",
                        height: "40px",
                        fontSize: "12px",
                        flexGrow: 1,
                      }}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        sx: { height: "40px" },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{option.itemNo}</span>
                      {option.inventory > 0 && (
                        <span style={{ fontWeight: "bold", color: "green" }}>
                          {option.inventory}
                        </span>
                      )}
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    setNo(newValue ? newValue.itemNo : "");
                    setDescription(newValue ? newValue.description : "");
                  }}
                  value={stockList.find((stock) => stock.itemNo === no) || null}
                  isOptionEqualToValue={(option, value) =>
                    option.itemNo === value.itemNo
                  }
                  fullWidth
                />
              </Box>
            </Box>
          </Grid>

          {/* Description Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Description*
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                required
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Quantity and Tread Depth Fields */}
          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Quantity
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={quantity}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setQuantity(Number(e.target.value))
                } // Convert to number
                fullWidth
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  sx: { height: "40px", fontSize: "12px" },
                }}
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Tread Depth
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={treadDepth}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTreadDepth(Number(e.target.value))
                } // Convert to number
                fullWidth
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  sx: { height: "40px", fontSize: "12px" },
                }}
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Position and Old Position Fields */}
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", color: "gray", mb: 1 }}
            >
              Position
            </Typography>
            <Select
              sx={{
                backgroundColor: "white",
                borderColor: "black",
                borderRadius: "0px",
                height: "40px",
                fontSize: "12px",
                flexGrow: 1,
              }}
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              displayEmpty
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenuItem-root": {
                      color: "black",
                      fontSize: "12px",
                    },
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>N/A</em>
              </MenuItem>
              <MenuItem value="A1">A1</MenuItem>
              <MenuItem value="A2">A2</MenuItem>
              <MenuItem value="A3">A3</MenuItem>
              <MenuItem value="A4">A4</MenuItem>
              <MenuItem value="B1">B1</MenuItem>
              <MenuItem value="B2">B2</MenuItem>
              <MenuItem value="B3">B3</MenuItem>
              <MenuItem value="B4">B4</MenuItem>
              <MenuItem value="C1">C1</MenuItem>
              <MenuItem value="C2">C2</MenuItem>
              <MenuItem value="C3">C3</MenuItem>
              <MenuItem value="C4">C4</MenuItem>
              <MenuItem value="D1">D1</MenuItem>
              <MenuItem value="D2">D2</MenuItem>
              <MenuItem value="D3">D3</MenuItem>
              <MenuItem value="D4">D4</MenuItem>
              <MenuItem value="E1">E1</MenuItem>
              <MenuItem value="E2">E2</MenuItem>
              <MenuItem value="E3">E3</MenuItem>
              <MenuItem value="E4">E4</MenuItem>
              <MenuItem value="F1">F1</MenuItem>
              <MenuItem value="F2">F2</MenuItem>
              <MenuItem value="F3">F3</MenuItem>
              <MenuItem value="F4">F4</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", color: "gray", mb: 1 }}
            >
              Old Position
            </Typography>
            <Select
              sx={{
                backgroundColor: "white",
                borderColor: "black",
                borderRadius: "0px",
                height: "40px",
                fontSize: "12px",
                flexGrow: 1,
              }}
              value={oldPosition}
              onChange={(e) => setOldPosition(e.target.value)}
              displayEmpty
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenuItem-root": {
                      color: "black",
                      fontSize: "12px",
                    },
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>N/A</em>
              </MenuItem>
              <MenuItem value="A1">A1</MenuItem>
              <MenuItem value="A2">A2</MenuItem>
              <MenuItem value="A3">A3</MenuItem>
              <MenuItem value="A4">A4</MenuItem>
              <MenuItem value="B1">B1</MenuItem>
              <MenuItem value="B2">B2</MenuItem>
              <MenuItem value="B3">B3</MenuItem>
              <MenuItem value="B4">B4</MenuItem>
              <MenuItem value="C1">C1</MenuItem>
              <MenuItem value="C2">C2</MenuItem>
              <MenuItem value="C3">C3</MenuItem>
              <MenuItem value="C4">C4</MenuItem>
              <MenuItem value="D1">D1</MenuItem>
              <MenuItem value="D2">D2</MenuItem>
              <MenuItem value="D3">D3</MenuItem>
              <MenuItem value="D4">D4</MenuItem>
              <MenuItem value="E1">E1</MenuItem>
              <MenuItem value="E2">E2</MenuItem>
              <MenuItem value="E3">E3</MenuItem>
              <MenuItem value="E4">E4</MenuItem>
              <MenuItem value="F1">F1</MenuItem>
              <MenuItem value="F2">F2</MenuItem>
              <MenuItem value="F3">F3</MenuItem>
              <MenuItem value="F4">F4</MenuItem>
            </Select>
          </Grid>

          {/* Serial Number Retired */}
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Serial Number Retired
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={serialNumberRetired}
                onChange={(e) => setSerialNumberRetired(e.target.value)}
                fullWidth
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Serial Number */}
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Serial Number
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
                fullWidth
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Reason for Removal */}
          <Grid item xs={12}>
            <Select
              sx={{
                backgroundColor: "white",
                borderColor: "black",
                borderRadius: "0px",
                height: "40px",
                fontSize: "12px",
                flexGrow: 1,
              }}
              value={removalReason}
              onChange={(e) => setRemovalReason(e.target.value)}
              displayEmpty
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenuItem-root": {
                      color: "black",
                      fontSize: "12px",
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      fontStyle: "normal",
                    },
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Choose Item</em>
              </MenuItem>
              <MenuItem value="TYRE BLOWN OUT">TYRE BLOWN OUT</MenuItem>
              <MenuItem value="BOLT IN TYRE">BOLT IN TYRE</MenuItem>
              <MenuItem value="BULGE IN TYRE">BULGE IN TYRE</MenuItem>
              <MenuItem value="CAP LIFTING">CAP LIFTING</MenuItem>
              <MenuItem value="FLAT SPOTS">FLAT SPOTS</MenuItem>
              <MenuItem value="LOW THREAD">LOW THREAD</MenuItem>
              <MenuItem value="PUNCTURE REPAIR">PUNCTURE REPAIR</MenuItem>
              <MenuItem value="REUSE">REUSE</MenuItem>
              <MenuItem value="RIM DAMAGED OR CKRACKED">
                RIM DAMAGED OR CKRACKED
              </MenuItem>
              <MenuItem value="RUN FLAT">RUN FLAT</MenuItem>
              <MenuItem value="SIDEWALL DAMAGE TO TYRE">
                SIDEWALL DAMAGE TO TYRE
              </MenuItem>
              <MenuItem value="CUT IN TYRE">CUT IN TYRE</MenuItem>
              <MenuItem value="TYRE RIMMED / DRIVEN FLAT">
                TYRE RIMMED / DRIVEN FLAT
              </MenuItem>
              <MenuItem value="SHOULDER /UNEVEN WEAR">
                SHOULDER /UNEVEN WEAR
              </MenuItem>
              <MenuItem value="UNREPAIRABLE">UNREPAIRABLE</MenuItem>
              <MenuItem value="WIRE OUT">WIRE OUT</MenuItem>
              <MenuItem value="TYRE WORK TO MIN TREAD DEPTH">
                TYRE WORK TO MIN TREAD DEPTH
              </MenuItem>
            </Select>
          </Grid>

          {/* Cancel and Save Buttons */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button variant="outlined" color="error" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ backgroundColor: "green", color: "white" }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "5px",
          height: "100%",
          backgroundColor: borderColor,
        }}
      />
    </Card>
  );
}
