import axios from "axios";

import { SIGNALR_NEGOTIATE_URL } from "../config-global";

const negotiate = async (username: string) => {
  const url = `${SIGNALR_NEGOTIATE_URL}?userId=${username}`;

  try {
    return await axios.get(url);
  } catch (err) {
    console.log(err);
    return null;
  }
};

const SignalRService = {
  negotiate,
};

export default SignalRService;
