import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import shipmentReducer from "./slices/shipment";
import breakdownReducer from "./slices/breakdown";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const shipmentPersistConfig = {
  key: "shipment",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  shipment: persistReducer(shipmentPersistConfig, shipmentReducer),
  breakdown: breakdownReducer,
});

export default rootReducer;
