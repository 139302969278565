import { useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config-global";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { COMMON } from "../../../theme/palette";
import configService from "../../../services/ConfigService";

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
  pageTitle?: string;
};

export default function Header({ onOpenNav, pageTitle }: Props) {
  const theme = useTheme();
  const [environment, setEnvironment] = useState("PROD");
  const [title, setTitle] = useState("Fitter-App");
  const { themeLayout } = useSettingsContext();
  const isNavHorizontal = themeLayout === "horizontal";
  const isNavMini = themeLayout === "mini";
  const isDesktop = useResponsive("up", "lg");
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  useEffect(() => {
    if (pageTitle) {
      setTitle(pageTitle);
    }

    const getConfig = async () => {
      try {
        configService.getConfig().then((data) => {
          console.log("Config data:", data);
          if (data && data.environment) setEnvironment(data.environment);
        });
      } catch (error) {
        console.error(error);
      }
    };

    getConfig();
  }, [pageTitle]);

  const renderContent = (
    <>
      <Logo sx={{ mr: 1.5 }} />
      

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}
        {environment === "dev" && (
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "14px", fontWeight: "800", color: "red" }}
          >
            {environment}
          </Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center", py: 0, px: 0 }}>
          <Typography variant="subtitle1">{title}</Typography>
        </Box>

        {/*<NotificationsPopover />*/}

        {/* <ContactsPopover /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: COMMON.appbar,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        // ...(isDesktop && {
        //   width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
        //   height: HEADER.H_DASHBOARD_DESKTOP,
        //   ...(isOffset && {
        //     height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        //   }),
        //   ...(isNavHorizontal && {
        //     width: 1,
        //     bgcolor: "background.default",
        //     height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        //     borderBottom: `dashed 1px ${theme.palette.divider}`,
        //   }),
        //   ...(isNavMini && {
        //     width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
        //   }),
        // }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
