import axios from "axios";
import { IStock } from "../@types/stock";
import { getStocks, saveStocks, clearStocks } from "../Idb/indexedDBHelper";

const getMyStock = async (company: string): Promise<Array<IStock>> => {
  try {
    // First, attempt to get stocks from IndexedDB
    const cachedStocks = await getStocks();
    if (cachedStocks.length > 0) {
      return cachedStocks;
    }

    // If no data in IndexedDB, fetch from the server
    const url = `/stocks/${company}`;
    const response = await axios.get<Array<IStock>>(url);
    const stocks = response.data;

    // Save fetched data to IndexedDB
    await saveStocks(stocks);
    return stocks;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const refreshStock = async (company: string): Promise<Array<IStock>> => {
  try {
    // Fetch fresh data from the server
    const url = `/stocks/${company}`;
    const response = await axios.get<Array<IStock>>(url);
    const stocks = response.data;

    // Clear old data in IndexedDB and save new data
    await clearStocks();
    await saveStocks(stocks);

    return stocks;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const StockService = {
  getMyStock,
  refreshStock,
};

export default StockService;
