import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import { useSettingsContext } from "../../components/settings";
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import NavHorizontal from "./nav/NavHorizontal";
import { TitleProvider, useTitleContext } from "../../contexts/TitleContext";

declare global {
  interface Window {
    _mtm: any;
  }
}

function DashboardLayoutContent() {
  const { themeLayout } = useSettingsContext();
  const isDesktop = useResponsive("up", "lg");
  const [open, setOpen] = useState(false);
  const isNavHorizontal = themeLayout === "horizontal";
  const isNavMini = themeLayout === "mini";
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} />
  );
  const { title } = useTitleContext();

  return (
    <>
      <Header onOpenNav={handleOpen} pageTitle={title} />
      <Box sx={{ display: { lg: "flex" }, minHeight: { lg: 1 } }}>
        {/*{isNavHorizontal && (isDesktop ? <NavHorizontal /> : renderNavVertical)}*/}
        {/*{isNavMini && (isDesktop ? <NavMini /> : renderNavVertical)}*/}
        {/*{!isNavHorizontal && !isNavMini && renderNavVertical}*/}
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}

export default function DashboardLayout() {
  return (
    <TitleProvider>
      <DashboardLayoutContent />
    </TitleProvider>
  );
}
