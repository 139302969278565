import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AddIcon from "@mui/icons-material/Add";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IBreakdown,
  IBreakdownCloseDto,
  IBreakdownLine,
} from "../../@types/breakdown";
import { useAuthContext } from "../../auth/useAuthContext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SalesOrdersService from "../../services/SalesOrdersService";
import Progress from "../progress/Progress";
import { BUTTONBAR } from "../../config-global";
import { useTitleContext } from "../../contexts/TitleContext";
import { GridDeleteIcon } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SignatureCanvas from "react-signature-canvas";

import configService from "../../services/ConfigService";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useSnackbar} from "notistack";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import SignalRService from "../../services/SignalRService";
import {NotificationItemProps} from "../../@types/notifications";

interface BreakdownFormProps {
  breakdown: IBreakdown;
  refreshBreakdown: () => void;
}

export default function BreakdownForm({
  breakdown,
  refreshBreakdown,
}: BreakdownFormProps) {
  const theme = useTheme();
  const { setTitle } = useTitleContext();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile only

  const [collapsed, setCollapsed] = useState(true);
  const [collapsedLines, setCollapsedLines] = useState(true);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const sigPad = useRef<SignatureCanvas | null>(null);

  const [progressVisible, setProgressVisible] = useState(false);
  const [assignedToMe, setAssignedToMe] = useState(false);

  const [openDeleteLineDialog, setOpenDeleteLineDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<IBreakdownLine | null>(null);

  const [imagesUrl, setImagesUrl] = useState<string | null>(null);

  const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState(false);
  const [selectedImageForDelete, setSelectedImageForDelete] = useState<
    string | null
  >(null);
  const [selectedImageIdForDelete, setSelectedImageIdForDelete] = useState<
    number | null
  >(null);

  const [workDescription, setWorkDescription] = useState<string>("");
  const [customerComment, setCustomerComment] = useState<string>("");
  const [fitterComment, setFitterComment] = useState<string>("");
  const [reTorque, setReTorque] = useState<boolean>(false);
  const [tyreCheck, setTyreCheck] = useState<boolean>(false);
  const [paymentEmail, setPaymentEmail] = useState<string>("");
  const [requirePayment, setRequirePayment] = useState<boolean>(
    breakdown?.selltoCustomerNo === "CASHSALE-STRIPE PAYM"
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [mileage, setMileage] = useState<number>(0);
  const [vehicleRegistrationNo, setVehicleRegistrationNo] =
    useState<string>("");
  const [imageCount, setImageCount] = useState<number>(1);

  const [timer, setTimer] = useState(30); // Set initial timer value to 30 seconds
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState<
      Array<NotificationItemProps>
  >([]);

  const [connection, setConnection] = useState<HubConnection | null>(null);


  useEffect(() => {
    const connectSignalR = async () => {
      const me = `${user?.surname} ${user?.givenName}`;
      const fitterId = me.replace(/\s+/g, "_").toLowerCase();
      const userName = fitterId;

      try {
        const signalrData = await SignalRService.negotiate(userName);
        if (signalrData) {
          const url = `${signalrData.data.url}&userId=${userName}`;
          const connection = new HubConnectionBuilder()
              .withUrl(url, {
                accessTokenFactory: () => signalrData.data.accessToken,
              })
              .withAutomaticReconnect()
              .build();

          connection.on("newMessage", (message: NotificationItemProps) => {
            enqueueSnackbar(message.Title, { variant: "info" });
            if (message.Title === "Line added succesfully" && message.No === breakdown?.no) {
              refreshBreakdown();
            }
          });

          connection.on("userMessage", (message: NotificationItemProps) => {
            enqueueSnackbar(message.Title, { variant: "info" });
            setNotifications((prev) => [message, ...prev]);
          });

          connection.on("salesOrder", (message: NotificationItemProps) => {
            enqueueSnackbar(message.Title, { variant: "info" });
            setNotifications((prev) => [message, ...prev]);
          });

          connection.on("payment", (message: NotificationItemProps) => {
            enqueueSnackbar(message.Title, { variant: "success" });
            setNotifications((prev) => [message, ...prev]);
          });

          connection.on("error", (message: NotificationItemProps) => {
            enqueueSnackbar(message.Title, { variant: "error" });
            alert(message.Title);
          });

          await connection.start();
          setConnection(connection);
        }
      } catch (error) {
        console.error("Error connecting SignalR:", error);
      }
    };

    // Call the async function
    connectSignalR();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [user, enqueueSnackbar, breakdown, refreshBreakdown]);

// Timer Effect
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          refreshBreakdown();
          return 30; // Reset timer
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timerId); // Cleanup interval on unmount
  }, [refreshBreakdown]);

// Configuration Fetch
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await configService.getConfig();
        if (data?.images_url) setImagesUrl(data.images_url);
        setPaymentEmail(user?.email || "");
        setPhoneNumber(user?.phoneNumber || "");
        setRequirePayment(breakdown?.selltoCustomerNo === "CASHSALE-STRIPE PAYM");
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();

    const me = `${user?.surname} ${user?.givenName}`;
    setAssignedToMe(breakdown?.fitter === me);

    if (breakdown?.no) {
      setTitle(`Order: ${breakdown.no}`);
    }

    setMileage(breakdown?.mileage || 0);
    setVehicleRegistrationNo(breakdown?.vehicleRegistrationNo || "");

    console.log("Breakdown: ", breakdown);
  }, [breakdown, user, setTitle]);
  const borderColor = breakdown?.company === "TAB" ? "darkgreen" :  breakdown?.company === "MTS" ? "black" : "blue";
  const handleOpenSignatureDialog = () => {
    setOpenSignatureDialog(true);
  };

  const handleCloseSignatureDialog = () => {
    setOpenSignatureDialog(false);
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };
  const handleFinishJob = async () => {
    if (sigPad.current && !sigPad.current.isEmpty()) {
      try {
        setProgressVisible(true);
        console.log("Progress visible in breakdown form", progressVisible);
        console.log("sigPad.current", sigPad.current);
        // Capture the signature as a PNG image
        const signature = sigPad.current.toDataURL("image/png");
        const signatureFileName = `${breakdown.no}_signature.png`;

        // Convert the base64 signature into a Blob
        const response = await fetch(signature);
        const blob = await response.blob();

        // Convert the Blob into a File
        const file = new File([blob], signatureFileName, { type: "image/png" });

        // Upload the signature image to your images folder
        const sastoken = await SalesOrdersService.generateSasUrl(
            breakdown.id,
            signatureFileName
        );
        await SalesOrdersService.uploadFileToBlobStorage(file, sastoken);

        const finishJobObject: IBreakdownCloseDto = {
          id: breakdown.id,
          no: breakdown.no,
          company: breakdown.company,
          vehicleRegistrationNo: vehicleRegistrationNo || "",
          signature: signature,
          customerComment: customerComment || "",
          fitterDescription: fitterComment || "",
          workDescription: workDescription || "",
          reTorque: reTorque || false,
          tyreCheck: tyreCheck || false,
          mileage: mileage || 0,
          phoneNumber: phoneNumber || "",
          requirePayment: requirePayment || false,
          paymentEmail: paymentEmail || "",
        };
        console.log("Finish Job Object", finishJobObject);
        const result = await SalesOrdersService.finishJob(
            breakdown.id,
            finishJobObject
        );
        console.log("Finish Job Result", result);
        // Close dialog and refresh the breakdown
        handleCloseSignatureDialog();
        refreshBreakdown();
        navigate('/salesorders');
      } catch (error) {
        console.error("Error finishing the job:", error);
      } finally {
        setProgressVisible(false);
      }
    } else {
      alert("Please provide a signature.");
    }
  };

  const handleOpenDeleteLineDialog = (item: IBreakdownLine) => {
    setItemToDelete(item);
    setOpenDeleteLineDialog(true);
  };

  const handleCloseDeleteLineDialog = () => {
    setOpenDeleteLineDialog(false);
    setItemToDelete(null);
  };

  const handleDelete = async () => {
    setProgressVisible(true);
    console.log("Delete Line", itemToDelete);
    if (itemToDelete?.orderId && itemToDelete?.id)
      await SalesOrdersService.deleteLineFromOrder(
          itemToDelete?.orderId,
          itemToDelete?.id
      );
    handleCloseDeleteLineDialog();
    refreshBreakdown();
    setCollapsedLines(true);
    setProgressVisible(false);
  };

  const handleBack = () => {
    navigate("/salesorders");
  };

  const handleAddPhotos = async () => {
    console.log("Add Photos");
    const input: HTMLInputElement = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.capture = "environment";

    // Add the input to the document body (it will be invisible)
    document.body.appendChild(input);

    // Listen for the change event to capture the selected file
    input.onchange = async (event: Event) => {
      setProgressVisible(true);

      const file: File | undefined = (event.target as HTMLInputElement)
        .files?.[0];
      if (file) {
        try {
          // Read the file as a data URL
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64data = reader.result as string;

            // Convert the base64 data URL to a Blob
            const response = await fetch(base64data);
            const blob = await response.blob();

            // Extract the file name and extension
            const fileNameWithoutExt = file.name.substring(
              0,
              file.name.lastIndexOf(".")
            );
            const fileExt = file.name.substring(file.name.lastIndexOf("."));

            // Generate the new file name with imageCount
            const newFileName = `${breakdown.no}-${fileNameWithoutExt}-${imageCount}${fileExt}`;
            const newFile = new File([blob], newFileName, {
              type: "image/png",
            });
            console.log("New file:", newFile);
            console.log("File Name:", newFile.name);

            const modifiedFileName = `${encodeURIComponent(
              newFile.name.replace(/\./g, "$")
            )}`;
            const sastoken = await SalesOrdersService.generateSasUrl(
              breakdown.id,
              modifiedFileName
            );

            // Upload the file directly to Azure Blob Storage
            await SalesOrdersService.uploadFileToBlobStorage(newFile, sastoken);
            console.log("Photo uploaded successfully");

            const fileUrl = `${imagesUrl}/${newFileName}`;
            console.log("File URL:", fileUrl);

            await SalesOrdersService.AddPhotoToOrder(breakdown.id, fileUrl);

            // Increment the imageCount
            setImageCount((prevCount) => prevCount + 1);
          };
          reader.readAsDataURL(file);
        } catch (error) {
          console.error("Error uploading photo:", error);
        }
      }
      refreshBreakdown();
      setProgressVisible(false);
    };

    // Trigger the file input dialog
    input.click();

    // Remove the input from the DOM after the click (cleanup)
    document.body.removeChild(input);
  };

  const handleAddLine = () => {
    console.log("Add Line with breakdown: ", breakdown);
    navigate("/salesorders/newline", { state: { breakdown: breakdown } });
  };

  const reload = () => {
    window.location.reload();
  };

  const handleUnassign = async () => {
    setProgressVisible(true);
    const id = breakdown.id;

    await SalesOrdersService.unassign(id.toString());

    setAssignedToMe(false);
    refreshBreakdown();
    setProgressVisible(false);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleCollapseLines = () => {
    setCollapsedLines(!collapsedLines);
  };

  const handleOpenPhotoDialog = (photo: string) => {
    console.log("Opening photo dialog:", photo);
    setSelectedPhoto(photo);
    setOpenPhotoDialog(true);
  };

  const handleClosePhotoDialog = () => {
    setOpenPhotoDialog(false);
  };

  const handleAssign = async () => {
    setProgressVisible(true);
    const id = breakdown.id;
    if (id) {
      await SalesOrdersService.assignToMe(id.toString());
      setAssignedToMe(true);
    }
    refreshBreakdown();
    setProgressVisible(false);
  };

  const handleOpenDeleteImageDialog = (image: string, id: number) => {
    setSelectedImageForDelete(image);
    setSelectedImageIdForDelete(id);
    setOpenDeleteImageDialog(true);
  };

  // Function to close delete confirmation dialog
  const pollForUpdate = async (interval = 500, retries = 5) => {
    let attempts = 0;
    const poll = async () => {
      if (attempts >= retries) {
        console.error("Max retries reached");
        return;
      }
      attempts++;
      await refreshBreakdown(); // Attempt to refresh the data

      // Check if the image is still there (you can change this condition depending on your data structure)
      const imageStillExists = breakdown.orderImages.some(
        (image) => image.id === selectedImageIdForDelete
      );

      if (!imageStillExists) {
        console.log("Image successfully removed from the breakdown");
        return;
      } else {
        console.log("Image still exists, retrying...");
        setTimeout(poll, interval); // Poll again after the interval
      }
    };
    poll();
  };

  const handleCloseDeleteImageDialog = () => {
    setOpenDeleteImageDialog(false);
    setSelectedImageIdForDelete(null);
    setSelectedImageForDelete(null);
    setProgressVisible(false);

    // Start polling to check for the update
    pollForUpdate();
  };

  // Function to delete the image (replace with actual delete service call)
  const handleDeleteImage = async () => {
    if (selectedImageForDelete) {
      setProgressVisible(true);
      console.log("Deleting image:", selectedImageForDelete);
      console.log("Image ID:", selectedImageIdForDelete);
      if (breakdown.id && selectedImageIdForDelete)
        await SalesOrdersService.deletePhotoFromOrder(
          breakdown.id,
          selectedImageIdForDelete
        );
      handleCloseDeleteImageDialog();
    }
  };

  return (
    <>
      <Progress visible={progressVisible} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "fixed",
          top: 70,
          left: BUTTONBAR.H_MOBILE_LEFT, //isMobile ? BUTTONBAR.H_MOBILE_LEFT : (isTablet ? BUTTONBAR.H_TABLET_LEFT : BUTTONBAR.H_DESKTOP_LEFT),
          width: "100%", //isMobile ? '100%' : (isTablet ? `calc(100% - ${BUTTONBAR.H_TABLET_LEFT}px)` : `calc(100% - ${BUTTONBAR.H_DESKTOP_LEFT}px)`),
          height: isMobile ? 56 : "auto",
          backgroundColor: "white",
          zIndex: 1000, // Ensures it's on top of other elements
          padding: "16px 16px",
          boxShadow: "0px 4px 2px -2px gray", // Adds shadow for better visibility
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={handleBack} sx={{ color: "black" }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: "black", fontSize: "9px" }}>
            Back
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={reload} sx={{ color: "black" }}>
            <RefreshIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: "black", fontSize: "9px" }}>
            Reload ({timer}s)
          </Typography>
        </Box>
        {assignedToMe ? (
          <>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton onClick={handleUnassign} sx={{ color: "black" }}>
                <PersonRemoveIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "black", fontSize: "9px" }}
              >
                Unassign
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={handleAddPhotos}
                sx={{ color: "white", backgroundColor: "black" }}
              >
                <PhotoCameraIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "black", fontSize: "9px" }}
              >
                Add Photos
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={handleAddLine}
                sx={{ color: "white", backgroundColor: "red", fontWeight: 900 }}
              >
                <AddIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "black", fontSize: "9px" }}
              >
                Add Line
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={handleOpenSignatureDialog}
                sx={{
                  color: "white",
                  backgroundColor: "green",
                  fontWeight: 800,
                }}
              >
                <CheckIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "black", fontSize: "9px" }}
              >
                Finish Job
              </Typography>
            </Box>
          </>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton onClick={handleAssign} sx={{ color: "black" }}>
              <PersonAddIcon />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ color: "black", fontSize: "9px" }}
            >
              Assign To Me
            </Typography>
          </Box>
        )}
      </Box>

      <Card sx={{ mt: isMobile ? 8 : 12 }}>
        <CardContent>
          <Box
            display="flex-start"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "9px",
                textAlign: "left",
                color: breakdown?.company === "TAB" ? "darkgreen" : "black",
              }}
            >
              {breakdown?.id} - {breakdown?.no}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "9px",
                textAlign: "right",
                color: breakdown?.company === "TAB" ? "darkgreen" : "black",
              }}
            >
              {breakdown?.company}
            </Typography>
          </Box>
          {/* Details */}
          <Grid container spacing={2}>
            {/* Customer Row */}
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "10px", color: "gray" }}
                  >
                    Customer:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                  >
                    {breakdown?.selltoCustomerName || "N/A"}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ ml: "auto" }}>
                  <Button
                    sx={{ color: "black", padding: 0, minWidth: "auto" }}
                    onClick={toggleCollapse}
                    startIcon={
                      collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />
                    }
                  ></Button>
                </Box>
              </Box>
            </Grid>

            {/* Location Row */}
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray" }}
                >
                  Location:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.location || "N/A"}
                </Typography>
              </Box>
            </Grid>

            {/* Location Code Row */}
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray" }}
                >
                  Location Code:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.locationCode || "N/A"}
                </Typography>
              </Box>
            </Grid>

            {/* Fitter Row */}
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray" }}
                >
                  Fitter:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.fitter || "N/A"}
                </Typography>
              </Box>
            </Grid>

            {/* Sales Person code */}
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray" }}
                >
                  Sales person code:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.salespersonCode || "N/A"}
                </Typography>
              </Box>
            </Grid>
            {!collapsed && (
              <>
                {/* Vehicle Reg Row */}
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "10px", color: "gray" }}
                    >
                      Vehicle Reg:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {breakdown?.vehicleRegistrationNo || "N/A"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Customer Comment Row */}
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "10px", color: "gray" }}
                    >
                      Customer comment:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {breakdown?.customerComment || "N/A"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Mileage Row */}
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "10px", color: "gray" }}
                    >
                      Mileage:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {breakdown?.mileage || "N/A"}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "5px",
            height: "100%",
            backgroundColor: borderColor,
          }}
        />
      </Card>

      {breakdown?.orderLines && breakdown?.orderLines.length > 0 && (
        <Card sx={{ mt: 1 }}>
          <CardContent>
            {/* Lines */}
            {breakdown?.orderLines?.map((line, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                  >
                    {line.description || "N/A"}
                  </Typography>
                  <Button
                    sx={{ color: "black", padding: 0, minWidth: "auto" }}
                    onClick={toggleCollapseLines}
                    startIcon={
                      collapsedLines ? <ExpandMoreIcon /> : <ExpandLessIcon />
                    }
                  />
                </Box>

                {!collapsedLines && (
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "10px", color: "gray" }}
                            >
                              Serial No / Retired:
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "black",
                              }}
                            >
                              {line.serialNoRetired || "N/A"}
                            </Typography>
                          </Box>
                          <Button
                            sx={{ color: "red", padding: 0, minWidth: "auto" }}
                            onClick={() => handleOpenDeleteLineDialog(line)}
                            startIcon={<GridDeleteIcon />}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "10px", color: "gray" }}
                        >
                          No:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          {line.no || "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1, pl: 1 }}>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "9px", color: "gray" }}
                        >
                          Position:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          {line.position || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "9px", color: "gray" }}
                        >
                          Old Position:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          {line.oldPosition || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "9px", color: "gray" }}
                        >
                          Quantity:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          {line.quantity || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "9px", color: "gray" }}
                        >
                          Tread Depth:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          {line.treadDepth || "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            ))}
          </CardContent>
        </Card>
      )}

      {breakdown?.orderImages && breakdown?.orderImages.length > 0 && (
        <Card sx={{ mt: 1 }}>
          <CardContent>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Box display="flex" flexWrap="wrap">
                  {breakdown?.orderImages?.map((photo, index) => (
                    <Box key={index} sx={{ margin: 1, position: "relative" }}>
                      <img
                        src={photo.imageUrl}
                        alt={`photo-${index}`}
                        style={{ width: 100, height: 100 }}
                        onClick={() => handleOpenPhotoDialog(photo.imageUrl)}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: "rgba(255,255,255,0.7)",
                        }}
                        onClick={() =>
                          handleOpenDeleteImageDialog(photo.imageUrl, photo.id)
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>

            {/* Dialog for confirming delete action */}
            <Dialog
              open={openDeleteImageDialog}
              onClose={handleCloseDeleteImageDialog}
              maxWidth="sm"
            >
              <DialogTitle>Confirm Image Deletion</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this image?
                </DialogContentText>
                {selectedImageForDelete && (
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <img
                      src={selectedImageForDelete}
                      alt="Selected"
                      style={{ width: "100%", maxHeight: "300px" }}
                    />
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDeleteImageDialog}
                  sx={{ color: "black" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteImage}
                  sx={{ border: 1, color: "red" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openPhotoDialog}
              onClose={handleClosePhotoDialog}
              maxWidth="sm"
            >
              <DialogTitle>Photo</DialogTitle>
              <DialogContent>
                {selectedPhoto && (
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <img
                      src={selectedPhoto}
                      alt="Selected"
                      style={{ width: "100%", maxHeight: "300px" }}
                    />
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClosePhotoDialog}
                  sx={{ border: 1, color: "black" }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      )}
      <Dialog
        open={openDeleteLineDialog}
        onClose={handleCloseDeleteLineDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item:{" "}
            <strong>{itemToDelete?.description}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteLineDialog} sx={{ color: "black" }}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            sx={{ border: 1, color: "red" }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSignatureDialog}
        onClose={handleCloseSignatureDialog}
        maxWidth="sm"
      >
        <DialogTitle>Sign to Finish Job</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Please sign below to finish the job:
          </Typography>
          <Box sx={{ border: "1px solid black", mt: 2 }}>
            <SignatureCanvas
              ref={sigPad}
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Vehicle Registration No:</Typography>
            <textarea
              value={vehicleRegistrationNo}
              onChange={(e) => setVehicleRegistrationNo(e.target.value)}
              style={{ width: "100%", height: 50 }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Work Description:</Typography>
            <textarea
              value={workDescription}
              onChange={(e) => setWorkDescription(e.target.value)}
              style={{ width: "100%", height: 50 }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Customer Comment:</Typography>
            <input
              type="text"
              value={customerComment}
              onChange={(e) => setCustomerComment(e.target.value)}
              style={{ width: "100%", height: 50 }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Fitter Comment:</Typography>
            <input
              type="text"
              value={fitterComment}
              onChange={(e) => setFitterComment(e.target.value)}
              style={{ width: "100%", height: 50 }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Mileage:</Typography>
            <input
              type="number"
              value={mileage}
              onChange={(e) => setMileage(Number(e.target.value))}
              style={{ width: "100%", height: 50 }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Re-Torque:</Typography>
            <input
              type="checkbox"
              checked={reTorque}
              onChange={(e) => setReTorque(e.target.checked)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Tyre Check:</Typography>
            <input
              type="checkbox"
              checked={tyreCheck}
              onChange={(e) => setTyreCheck(e.target.checked)}
            />
          </Box>
          {requirePayment && (
            <>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Customer Phone:</Typography>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setFitterComment(e.target.value)}
                  style={{ width: "100%", height: 50 }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Customer Email:</Typography>
                <input
                  type="text"
                  value={paymentEmail}
                  onChange={(e) => setFitterComment(e.target.value)}
                  style={{ width: "100%", height: 50 }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSignatureDialog}
            sx={{ border: 0, color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={handleFinishJob} sx={{ border: 1, color: "green" }}>
            Finish Job
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
