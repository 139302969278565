import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import {CheckCircle} from "@mui/icons-material";

interface IFilterCompaniesProps {
    showMTS: boolean;
    showTAB: boolean;
    showMTSC: boolean;
    onFitterFilter: (value: boolean) => void;
    onMtsFilter: (value: boolean) => void;
    onTabFilter: (value: boolean) => void;
    onMtscFilter: (value: boolean) => void;
    resource: string;
    showAdditionalButtons?: boolean;
    handleRefreshList?: () => void;
    handleAddNewElement?: () => void;
    timer?: number;
}

export default function FilterCompanies({
                                            showMTS,
                                            showTAB,
                                            showMTSC,
                                            onFitterFilter,
                                            onMtsFilter,
                                            onTabFilter,
                                            onMtscFilter,
                                            resource,
                                            showAdditionalButtons = false,
                                            handleRefreshList,
                                            handleAddNewElement,
                                            timer
                                        }: IFilterCompaniesProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [filterFitterOnly, setFilterFitterOnly] = React.useState(false);
    const [mtsChecked, setMtsChecked] = React.useState(true);
    const [tabChecked, setTabChecked] = React.useState(true);
    const [mtscChecked, setMtscChecked] = React.useState(true);

    const handleFilterFitterOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterFitterOnly(event.target.checked);
        onFitterFilter(event.target.checked);
    }

    const handleFilterMTS = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMtsChecked(event.target.checked);
        onMtsFilter(event.target.checked);
    }

    const handleFilterTAB = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTabChecked(event.target.checked);
        onTabFilter(event.target.checked);
    }

    const handleFilterMTSC = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMtscChecked(event.target.checked);
        onMtscFilter(event.target.checked);
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                position: "fixed",
                top: 70,
                left: "0%",
                width: "100%",
                height: isMobile ? 56 : "auto",
                backgroundColor: "white",
                zIndex: 1000,
                padding: "16px 16px",
                boxShadow: "0px 4px 2px -2px gray",
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
                        checked={filterFitterOnly}
                        onChange={handleFilterFitterOnly}
                    />
                }
                label={`"My ${resource}"`}
            />
            {showMTS && (
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
                            checked={mtsChecked}
                            onChange={handleFilterMTS}
                        />
                    }
                    label={
                        <Typography sx={{ fontWeight: showMTS ? 700 : 400 }}>
                            MTS
                        </Typography>
                    }
                />
            )}
            {showTAB && (
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ color: "green", "&.Mui-checked": { color: "green" } }}
                            checked={tabChecked}
                            onChange={handleFilterTAB}
                        />
                    }
                    label={
                        <Typography sx={{ fontWeight: showTAB ? 700 : 400 }}>
                            TAB
                        </Typography>
                    }
                />
            )}
            {showMTSC && (
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ color: "blue", "&.Mui-checked": { color: "blue" } }}
                            checked={mtscChecked}
                            onChange={handleFilterMTSC}
                        />
                    }
                    label={
                        <Typography sx={{ fontWeight: showMTSC ? 700 : 400 }}>
                            MTSC
                        </Typography>
                    }
                />
            )}
            {showAdditionalButtons && (
                <>
                    <Box display="flex" flexDirection="column" alignItems="center"
                         sx={{borderLeft: "1px solid black", paddingLeft: "10px", marginLeft: "10px"}}
                    >
                    <IconButton onClick={handleRefreshList} sx={{ color: "black" }}>
                            <RefreshIcon />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: "black", fontSize: "12px" }}>
                            Reload: {timer}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center"
                         sx={{border: "1px solid black",
                            borderRadius: "9px",    
                             paddingLeft: "0px", marginLeft: "10px"}}
                    >
                        <IconButton onClick={handleAddNewElement} sx={{ color: "green" }}>
                            <AddIcon />
                        </IconButton>
                        <Typography
                            variant="body2"
                            sx={{ color: "black", fontSize: "12px" }}
                        >
                            New
                        </Typography>
                    </Box>

                </>
            )}
        </Box>
    );
}