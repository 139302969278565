import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Container, Button, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAuthContext } from "src/auth/useAuthContext";
import { IBreakdown } from "../../@types/breakdown";
import SalesOrdersService from "../../services/SalesOrdersService";
import Progress from "../../components/progress/Progress";
import BreakdownForm from "../../components/SalesOrders/BreakdownForm";
import { useTitleContext } from "../../contexts/TitleContext";
import { BUTTONBAR } from "../../config-global";

export default function Breakdown() {
  const { setTitle } = useTitleContext();
  const { isAuthenticated } = useAuthContext();
  const { id } = useParams<{ id: string }>();
  const [progressVisible, setProgressVisible] = useState(false);
  const [breakdown, setBreakdown] = useState<IBreakdown | null>(null);
  const navigate = useNavigate();
  const refreshBreakdown = async () => {
    if (id) {
      console.log("Refreshing breakdown for id:", id);
      console.log("Current breakdown:", breakdown);
      const data = await SalesOrdersService.getBreakdown(id);
      console.log("New breakdown:", data);
      if (data) {
        console.log("New breakdown:", data);
        setBreakdown(data);
      }
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setProgressVisible(true);
    setTitle("SalesOrder");
    if (isAuthenticated) {
      if (id) {
        (async function fetchBreakdown() {
          const data = await SalesOrdersService.getBreakdown(id);
          setBreakdown(data);
        })();
      }
      setProgressVisible(false);
    }
  }, [isAuthenticated, setTitle, id]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSelfAssign = async () => {
    setProgressVisible(true);
    await SalesOrdersService.assignToMe(id ?? "");
    setProgressVisible(false);
  };

  return (
    <Container>
      <Progress visible={progressVisible} />
      <Helmet>
        <title>Sales Order {id}</title>
      </Helmet>

      {/*<Box*/}
      {/*  display="flex"*/}
      {/*  justifyContent="space-between"*/}
      {/*  alignItems="center"*/}
      {/*  sx={{*/}
      {/*    position: "fixed",*/}
      {/*    top: 70,*/}
      {/*    left: isMobile ? BUTTONBAR.H_MOBILE_LEFT : BUTTONBAR.H_DESKTOP_LEFT,*/}
      {/*    width: !isMobile*/}
      {/*      ? `calc(100% - ${BUTTONBAR.H_DESKTOP_LEFT}px)`*/}
      {/*      : "100%",*/}
      {/*    height: isMobile ? 56 : "auto",*/}
      {/*    backgroundColor: "white",*/}
      {/*    zIndex: 1000, // Ensures it's on top of other elements*/}
      {/*    padding: "16px 16px",*/}
      {/*    boxShadow: "0px 4px 2px -2px gray", // Adds shadow for better visibility*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Button*/}
      {/*    variant="contained"*/}
      {/*    onClick={handleBack}*/}
      {/*    startIcon={<ArrowBackIcon />}*/}
      {/*    sx={{ backgroundColor: "black", color: "white", paddingLeft: "25px" }}*/}
      {/*  >*/}
      {/*    Back*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    variant="contained"*/}
      {/*    onClick={handleSelfAssign}*/}
      {/*    startIcon={<PersonAddIcon />}*/}
      {/*    sx={{ backgroundColor: "black", color: "white", paddingLeft: "25px" }}*/}
      {/*  >*/}
      {/*    Assign to me*/}
      {/*  </Button>*/}
      {/*</Box>*/}

      {breakdown && (
        <BreakdownForm
          breakdown={breakdown}
          refreshBreakdown={refreshBreakdown}
        />
      )}
    </Container>
  );
}
