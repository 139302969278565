import { Helmet } from "react-helmet-async";

import { Container, Typography } from "@mui/material";

export default function Notifications() {
  return (
    <Container>
      <Helmet>
        <title>Notifications | Fitter App</title>
      </Helmet>
      <Typography variant="h3" gutterBottom>
        Notifications
      </Typography>
    </Container>
  );
}
