import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DiagnosticService from "src/services/DiagnosticsService";
import { Box, CardContent, CardHeader, Grid, Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Diagnostic, IDiagnosticCardProps } from "src/@types/diagnostic";
import Progress from "../progress/Progress";

const columns: GridColDef[] = [
  { field: "measurementId", headerName: "Id", width: 90 },
  { field: "tyreGrooveNumber", headerName: "Tyre Groove Number", width: 90 },
  { field: "axlePosition", headerName: "Axle Position", width: 90 },
  { field: "tyrePosition", headerName: "Tyre Position", width: 90 },
  { field: "measurementType", headerName: "Type", width: 90 },
  { field: "measurementTypeName", headerName: "Type Name", width: 120 },
  { field: "measurementUnit", headerName: "Unit", width: 90 },
  { field: "displayValue", headerName: "Value", width: 90 },
];

const diagnosticParamName = "diagnostic";

export default function DiagnosticCard({
  diagnosticProp,
}: IDiagnosticCardProps) {
  const [diagnosticParam, setDiagnosticParam] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticated();
  const [DiagnosticItem, setDiagnostic] = useState<Diagnostic | null>(
    diagnosticProp
  );
  const [progressVisible, setProgressVisible] = useState(false);

  useEffect(() => {
    const diagnostic = searchParams.get(diagnosticParamName);
    if (diagnostic) {
      setDiagnosticParam(diagnostic);
      setSearchParams();
    }
  }, [diagnosticParam, searchParams, setSearchParams]);

  useEffect(() => {
    if (diagnosticParam && isAuthenticated) {
      setProgressVisible(true);

      (async function loadDiagnosticData() {
        const tokenParam = diagnosticParam || "";
        const diagnosticData = await DiagnosticService.getDiagnostic(
          tokenParam
        );
        if (diagnosticData && diagnosticData.data) {
          setDiagnostic(diagnosticData.data);
          setProgressVisible(false);
        } else {
          setProgressVisible(false);
        }
      })();
    }
  }, [diagnosticParam, isAuthenticated]);

  return (
    <>
      <Progress visible={progressVisible} />
      {DiagnosticItem && (
        <Box sx={{ m: 2 }}>
          <Card>
            <CardHeader
              title={DiagnosticItem.measurementHeader.vehicleRegistrationNumber}
              subheader={DiagnosticItem.measurementHeader.userCode}
            />
            <CardContent>
              <Grid container>
                <Grid xs={1}>
                  <Box
                    marginTop={1}
                    marginRight={1}
                    textAlign="right"
                    color="text.secondary"
                  >
                    Document Date
                  </Box>
                </Grid>
                <Grid xs={2}>
                  <Box marginTop={1} border={0} fontWeight="bold">
                    {DiagnosticItem.measurementHeader.inspectionDate}
                  </Box>
                </Grid>
                <Grid xs={1}>
                  <Box
                    marginTop={1}
                    marginRight={1}
                    textAlign="right"
                    color="text.secondary"
                  >
                    Company Name
                  </Box>
                </Grid>
                <Grid xs={2}>
                  <Box marginTop={1} border={0} fontWeight="bold">
                    {DiagnosticItem.measurementHeader.companyName}
                  </Box>
                </Grid>
                <Grid xs={1}>
                  <Box
                    marginTop={1}
                    marginRight={1}
                    textAlign="right"
                    color="text.secondary"
                  >
                    Location
                  </Box>
                </Grid>
                <Grid xs={2}>
                  <Box marginTop={1} border={0} fontWeight="bold">
                    {DiagnosticItem.measurementHeader.locationName}
                  </Box>
                </Grid>
                <Grid xs={1}>
                  <Box
                    marginTop={1}
                    marginRight={1}
                    textAlign="right"
                    color="text.secondary"
                  >
                    Status
                  </Box>
                </Grid>
                <Grid xs={2}>
                  <Box marginTop={1} border={0} fontWeight="bold">
                    {DiagnosticItem.measurementHeader.inspectionNumber}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <DataGrid
            rows={DiagnosticItem.measurementDetails}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            getRowId={(row) => row.measurementId}
          />
        </Box>
      )}
    </>
  );
}
