import http from "axios";
import {
  IBreakdown,
  IBreakdownCloseDto,
  IBreakdownDto,
  IBreakdownLineDto,
} from "../@types/breakdown";
import { IStock } from "../@types/stock";
import axios from "axios";

export const getPagedBreakdowns = async (): Promise<Array<IBreakdown>> => {
  try {
    const url = `/orders`;
    const { data } = await http.get(url);

    return data;
  } catch (error) {
    console.error(error);
    return null as never;
  }
};

export const getBreakdown = async (id: string): Promise<IBreakdown> => {
  try {
    const url = `/orders/${id}`;
    const { data } = await http.get(url);

    return data;
  } catch (error) {
    console.error(error);
    return null as never;
  }
};

export const assignToMe = async (id: string): Promise<void> => {
  try {
    const url = `/orders/${id}/assign`;
    await http.post(url);
  } catch (error) {
    console.error(error);
  }
};

export const unassign = async (id: string): Promise<void> => {
  try {
    const url = `/orders/${id}/unassign`;
    await http.post(url);
  } catch (error) {
    console.error(error);
  }
};

const addLineToOrder = async (
  order: number,
  stock: IBreakdownLineDto
): Promise<IStock> => {
  try {
    const url = `/orders/${order}/lines`;
    const response = await axios.post<IStock>(url, stock);
    return response.data;
  } catch (error) {
    console.error(error);
    return {} as IStock;
  }
};

const deleteLineFromOrder = async (
  order: number,
  line: number
): Promise<void> => {
  try {
    const url = `/orders/${order}/lines/${line}`;
    await axios.delete(url);
  } catch (error) {
    console.error(error);
  }
};

const generateSasUrl = async (id: number, fileName: string) => {
  try {
    console.log("Generating SAS URL for", fileName);
    const response = await axios.get(`orders/${id}/sastoken/${fileName}`);
    return response.data;
  } catch (error) {
    console.error("Error generating SAS URL:", error);
    return null;
  }
};

const uploadFileToBlobStorage = async (
  file: File,
  sasUrl: string
): Promise<void> => {
  try {
    console.log("Uploading file to Blob Storage:", file, sasUrl);

    // Send the PUT request to Azure Blob Storage with the SAS URL
    const response = await axios.put(sasUrl, file, {
      headers: {
        "x-ms-blob-type": "BlockBlob", // Required for Azure Blob Storage
        "Content-Type": file.type, // Set the correct content type
      },
      // Disable Authorization header for this specific request
      transformRequest: [
        (data, headers) => {
          delete headers["Authorization"];
          return data;
        },
      ],
    });

    console.log("File uploaded successfully", response);
  } catch (error) {
    console.error("Error uploading file to Blob Storage:", error);
  }
};

const AddPhotoToOrder = async (
  id: number,
  modifiedFileName: string
): Promise<void> => {
  try {
    console.log("Adding photo to order:", modifiedFileName);
    const url = `/orders/${id}/photos`;
    await axios.post(url, { fileName: modifiedFileName });
    return;
  } catch (error) {
    console.error("Error adding photo to order:", error);
    return;
  }
};

const deletePhotoFromOrder = async (
  id: number,
  imageId: number
): Promise<void> => {
  try {
    console.log("Deleting photo from order:", imageId);
    const url = `/orders/${id}/images/${imageId}`;
    await axios.delete(url);
    return;
  } catch (error) {
    console.error("Error deleting photo from order:", error);
    return;
  }
};

const getPermissions = async (): Promise<{ mts: boolean; tab: boolean; mtsc: boolean }> => {
  try {
    const url = `/orders/permissions`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error getting permissions:", error);
    return { mts: false, tab: false, mtsc: false };
  }
};

const createOrder = async (order: IBreakdownDto): Promise<IBreakdown> => {
  try {
    const url = `/orders`;
    const response = await axios.post<IBreakdown>(url, order);
    return response.data;
  } catch (error) {
    console.error("Error creating order:", error);
    return {} as IBreakdown;
  }
};

const finishJob = async (
  id: number,
  finishJobObject: IBreakdownCloseDto
): Promise<void> => {
  try {
    const url = `/orders/${id}/close`;
    await axios.post(url, finishJobObject);
  } catch (error) {
    console.error("Error finishing job:", error);
  }
};

const deleteBreakdown = async (id: number): Promise<void> => {
  try {
    const url = `/orders/${id}`;
    await axios.delete(url);
  } catch (error) {
    console.error("Error deleting breakdown:", error);
  }
};

const SalesOrdersService = {
  getPagedBreakdowns,
  getBreakdown,
  assignToMe,
  unassign,
  addLineToOrder,
  deleteLineFromOrder,
  uploadFileToBlobStorage,
  generateSasUrl,
  AddPhotoToOrder,
  deletePhotoFromOrder,
  getPermissions,
  createOrder,
  finishJob,
  deleteBreakdown,
};
export default SalesOrdersService;
