import axios from "axios";

const pagedDiagnostics = async (page: number, size: number) => {
  const url = `/measurements`;
  if (!page) page = 0;
  if (!size) size = 20;
  try {
    return await axios.get(url, { params: { page, size } });
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getDiagnostic = async (no: string) => {
  const url = `/measurements/${no}`;

  try {
    return await axios.get(url, { params: { no } });
  } catch (err) {
    console.log(err);
    return null;
  }
};

const DiagnosticsService = {
  pagedDiagnostics,
  getDiagnostic,
};

export default DiagnosticsService;
