import http from "axios";
import { TransferObject } from "../@types/stock";

const GetCurrentVan = async () => {
  try {
    const response = await http.get("/transfers/currentVan");
    return response.data;
  } catch (error) {
    console.error("Error fetching current van details:", error);
    return null;
  }
};

const GetCurrentStock = async (van: string) => {
  try {
    const response = await http.get(`/transfers/currentStock/${van}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching current stock:", error);
    return [];
  }
};

const GetSources = async (fitter: string) => {
  try {
    const response = await http.get(`/transfers/sources`);
    return response.data;
  } catch (error) {
    console.error("Error fetching source warehouses:", error);
    return [];
  }
};

const GetDestinations = async (fitter: string) => {
  try {
    const response = await http.get(`/transfers/destinations`);
    return response.data;
  } catch (error) {
    console.error("Error fetching destination warehouses:", error);
    return [];
  }
};

const GetProducts = async (warehouseId: string) => {
  try {
    const response = await http.get(`/transfers/products/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

const Create = async (transferObject: TransferObject) => {
  try {
    const response = await http.post("/transfers/create", transferObject);
    return response.data;
  } catch (error) {
    console.error("Error creating transfer:", error);
    return null;
  }
};

const TransfersService = {
  GetCurrentVan,
  GetCurrentStock,
  GetSources,
  GetDestinations,
  GetProducts,
  Create,
};

export default TransfersService;
