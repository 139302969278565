import http from "axios";
import { ICustomer } from "../@types/customer";

export const getCustomers = async (): Promise<Array<ICustomer>> => {
  try {
    const url = `/customers`;
    const { data } = await http.get(url);

    return data;
  } catch (error) {
    console.error(error);
    return null as never;
  }
};

const CustomersService = {
  getCustomers,
};

export default CustomersService;
