import { createSlice, Dispatch } from "@reduxjs/toolkit";
// utils
import ShipmentsService from "src/services/ShipmentsService";

// ----------------------------------------------------------------------

const initialState: {
  isLoading: boolean;
  total: number;
  data: any[];
  total_pages: number;
  error: null;
} = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  total_pages: 0,
};

const slice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SHIPMENTS
    getShipmentsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload.data.map((item: any) => ({
        header: {
          ...item.shipmentHeader,
          createdAt: new Date(item.shipmentHeader.createdAt),
          documentDate: new Date(item.shipmentHeader.documentDate),
        },
        details: item.shipmentDetails,
      }));
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getShipmentsSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getShipments() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ShipmentsService.GetShipments();
      dispatch(slice.actions.getShipmentsSuccess({ data: response }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
